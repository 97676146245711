const EVENT_CATEGORY = 'SEVEN.LiveMTS';

const attachScript = (link) => {
  const ga = document.createElement('script');
  ga.type = 'text/javascript';
  ga.async = true;
  ga.src = link;
  document.body.appendChild(ga);
};

// eslint-disable-next-line prefer-rest-params
function gtag() { window.dataLayer.push(arguments); }

function initGA4(companyId) {
  attachScript(`https://www.googletagmanager.com/gtag/js?id=${companyId}`);
  window.dataLayer = window.dataLayer || [];
  window.gtag = window.gtag || gtag;
  gtag('js', new Date());

  gtag('config', companyId, { cookie_flags: 'SameSite=None; Secure' });
}

const gaMixin = {
  methods: {
    sendGA4Event(eventAction = '', eventLabel = '') {
      if (window.gtag) {
        window.gtag('event', eventAction, { event_category: EVENT_CATEGORY, event_label: eventLabel });
      }
    },
  },
};

export {
  initGA4,
  gaMixin,
};
