import defaultParams from '@/api/defaultParams';

export default {
  appOnline: state => state.appOnline,
  authToken: state => state.settings.auth.token,
  betAssist: state => state.betAssist,
  betAssistPopupId: state => state.betAssistPopupId,
  betRecommendation: state => state.betRecommendation,
  config: state => state.config,
  defaultTimeValues: state => state.defaultTimeValues,
  notifications: state => state.notifications,
  reofferedTicketLocalUuid: state => state.reofferedTicketLocalUuid,
  reOfferedTicketHandled: state => state.reOfferedTicketHandled,
  settings: state => state.settings,
  sidebarExpanded: state => state.sidebarExpanded,
  userBalance: state => state.balance,
  userCurrency: state => state.settings.user.currency,
  userLanguage: state => state.settings.language,
  userLogged: state => state.settings.user.logged,
  ticketReofferActive: state => state.reOfferedTicketUIActive,
  timezone: state => (state.settings.user.profile
    ? state.settings.user.profile.timezone : state.settings.timezone || defaultParams.timezone),
  translations: state => state.translations,
  translate: state => key => state.translations[key]
    || (state.config.displayTranslationKeys ? key : ''),
  valueTranslate: state => (key, values) => {
    if (state.translations[key]) {
      return _.reduce(values, (result, value) => _.replace(`${result}`, '{{value}}', value), state.translations[key]);
    }
    return state.config.displayTranslationKeys ? key : '';
  },
  companyName: state => state.companyName,
  sourceCompanyName: state => state.sourceCompanyName,
  generateCodeEnabled: state => state.config.generateCodeEnabled,
  cpvUuid: state => state.cpvUuid,
  toggledOffTournaments: state => state.toggledOffTournaments,
  lmtLoaded: state => state.lmtLoaded,
  scrollbarWidth: state => state.browserScrollbarWidth,
  predefinedStakeActive: state => state.predefinedStakeActive,
  predefinedStakeSelectedValue: state => state.predefinedStakeSelectedValue,
  liveMatchesCount: state => (state.liveMatchesCount + state.oddinLiveMatchesCount),
  oddinESportsData: state => state.oddinESportsData,
  oddinLiveMatchesCount: state => state.oddinLiveMatchesCount,
  oddinPrematchMatchesCount: state => state.oddinPrematchMatchesCount,
};
