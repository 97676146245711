import axios from 'axios';
import { routes } from '../../config/index';

const cmsUrl = {
  live: 'live_web_iframe_app',
};

export default {
  getLiveTicketHistoryList({
    token,
    companyId,
    cpvUuid,
    language,
    timezone,
    filter,
    dcUrlFragmentCl,
  }) {
    const liveTicketsApi = _.replace(routes.liveTickets, '{dcUrlFragmentCl}', dcUrlFragmentCl || 'gb');

    return axios({
      url: `${liveTicketsApi}/ticketsHistory/${cpvUuid}.json`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'SEVEN-LOCALE': language,
        'HTTP-X-SEVEN-CLUB-UUID': companyId,
      },
      params: {
        cpvUuid,
        count: filter.count || 1000,
        id_language: language,
        isFullHistoryRequest: true,
        product: 'LiveBetting',
        timeFrom: filter.params.timeFrom,
        timeTo: filter.params.timeTo,
        timezone,
      },
    });
  },
  /**
   * Company applications settings from CMS.
   *  Stored under ${companyName}_smweb_iframe_app.
   *
   * @param {string} [companyName='']
   * @returns {Promise<any>}
   */
  getCompanyAppSettings(companyName = '', product) {
    const franchises = {
      seven: 'winner',
      magnum: 'winner',
    };
    const reqCompanyName = franchises[companyName] || companyName;
    const options = {
      method: 'get',
      params: {
        templateMerge: true,
      },
      url: `${routes.gravityApi}/v1/apps/${reqCompanyName}_${cmsUrl[product]}/settings`,
    };

    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  getWebAppConfig(company = {}) {
    const options = {
      method: 'get',
      url: `${routes.smGateway}/web/config`,
      headers: { companyUuid: company.uuid },
    };

    return axios(options)
      .then(response => response.data)
      .catch(err => err);
  },

  getBanners(companyName, lang, type = 'prematch') {
    const appName = `${companyName}_${cmsUrl[type]}`;
    const options = {
      method: 'get',
      url: `${routes.gravityApi}/v1/marketing/slots`,
      headers: {
        'X-Nsft-SCD-Locale': lang,
        'X-Nsft-SCD-App-Name': appName,
      },
    };
    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  getLiveBanners(companyName, lang) {
    const appName = `${companyName}_live_web_iframe_app`;
    const options = {
      method: 'get',
      url: `${routes.gravityApi}/v1/marketing/slots`,
      headers: {
        'X-Nsft-SCD-Locale': lang,
        'X-Nsft-SCD-App-Name': appName,
      },
    };
    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  loadLiveTickets({
    cpvUuid,
    timezone,
    language,
    companyUuid,
    token,
    dcUrl,
    historyTicketCount,
  }) {
    const notstatus = 'CLOSED,REJECTED,AFTERCLOSED';
    const product = 'LiveBetting';
    const ticketsApi = _.replace(routes.liveTickets, '{dcUrlFragmentCl}', dcUrl || 'gb');

    return axios({
      url: `${ticketsApi}/ticketsHistory/${cpvUuid}.json`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'http-x-seven-club-uuid': companyUuid,
        'http-x-nab-dp': 'Web',
      },
      params: {
        count: historyTicketCount,
        notstatus,
        product,
        timezone,
        cpvUuid,
        id_language: language,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  loadCashoutTickets({
    companyUuid,
    token,
  }) {
    const limit = 50;
    const offset = 0;
    const sort = '-creationDatetime';

    return axios({
      url: `${routes.liveCashout}/v1/tickets`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        limit,
        offset,
        sort,
        companyUuid,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  cashoutTicket({
    companyUuid,
    token,
    cashoutSettings,
    ticket,
  }) {
    const { ticketHash } = ticket;
    const currentCashoutAmount = ticket.cashoutAmountAfterTax.toFixed(2);
    const acceptAnyAmountChange = cashoutSettings.options[0].value === cashoutSettings.selected;
    const acceptHigherAmountChange = cashoutSettings.options[1].value === cashoutSettings.selected;

    const requestPath = `v1/cashout/${ticketHash}/${currentCashoutAmount}`;

    return axios({
      url: `${routes.liveCashout}/${requestPath}`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        companyUuid,
        acceptAnyAmountChange,
        acceptHigherAmountChange,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  setAutocashoutTicket({
    ticketHash,
    amount,
    token,
    companyUuid,
    isAmountBeforeTax,
  }) {
    return axios({
      url: `${routes.liveCashout}/v1/cashout/auto/${ticketHash}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        companyUuid,
      },
      data: {
        amount,
        isAmountBeforeTax,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  removeAutocashoutRule({
    ticketHash,
    token,
    companyUuid,
  }) {
    return axios({
      url: `${routes.liveCashout}/v1/cashout/auto/${ticketHash}`,
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        companyUuid,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },

  getLiveTicketDetails({
    ticketHash,
    language,
    token,
    dcUrlFragmentCl,
  }) {
    const ticketsApi = _.replace(routes.liveTickets, '{dcUrlFragmentCl}', dcUrlFragmentCl || 'gb');

    return axios({
      url: `${ticketsApi}/tickets/${ticketHash}.json`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'SEVEN-LOCALE': language,
      },
      params: {
        id_language: language,
      },
    })
      .then(response => response.data);
  },

  getLiveTicketsDetails({
    ticketHashes,
    language,
    companyUuid,
    token,
  }) {
    return axios({
      url: `${routes.liveSocket}/liveTicket.php/tickets`,
      method: 'get',
      headers: {
        'company-uuid': companyUuid,
        Authorization: `Bearer ${token}`,
      },
      params: {
        ticketHashes,
        language,
      },
    })
      .then(response => response.data);
  },

  getStreamUrl({
    cpv,
    language,
    matchId,
    companyUuid,
    token,
  }) {
    const options = {
      url: `${routes.liveSocket}/streaming.php/stream`,
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        cpv,
        language,
        platform: 'web',
        matchId,
        token: 'web',
        clubUuid: companyUuid,
        bitrate: 1264000,
      },
    };
    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },
  getBGStreamUrl(url) {
    const options = {
      url,
      method: 'get',
    };

    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },
  getLiveConfiguration(cpvUuid, locale) {
    return axios({
      url: `${routes.liveSocket}/clientConfiguration.php/configuration`,
      method: 'get',
      params: {
        cpvUuid,
        locale,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },
  getBonusConfiguration(cpvUuid, url, companyUuid) {
    return axios({
      url,
      method: 'get',
      params: {
        cpvUuid,
        playerUuid: companyUuid,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },
  liveTicketPayin(payload) {
    const dcUrl = payload.config.dcUrlFragmentCl || 'gb';
    const sevenApi = _.replace(routes.sevenApi, '{dcUrlFragmentCl}', dcUrl);
    const ticketData = {
      ticket: payload.ticket,
      metadata: payload.metadata,
    };
    const options = {
      method: 'POST',
      url: `${sevenApi}/web/tickets/add.json`,
      params: {
        requestUuid: payload.metadata.requestUuid,
      },
      headers: {
        Authorization: `Bearer ${payload.settings.user.token}`,
        'SEVEN-TP-TOKEN': payload.settings.auth.tpToken,
        'HTTP-X-SEVEN-CLUB-UUID': payload.settings.company.id,
        'HTTP-X-NAB-DP': 'Web',
        'SEVEN-LOCALE': payload.settings.language,
      },
      data: ticketData,
    };

    return axios(options)
      .then(response => response)
      .catch(err => Promise.reject(err.response));
  },
  getPendingTicket(payload) {
    const dcUrl = payload.config.dcUrlFragmentCl || 'gb';
    const sevenApi = _.replace(routes.sevenApi, '{dcUrlFragmentCl}', dcUrl);
    const options = {
      method: 'GET',
      url: `${sevenApi}/web/tickets/request/${payload.requestUuid}/product/LiveBetting/check.json`,
      headers: {
        Authorization: `Bearer ${payload.settings.user.token}`,
        'HTTP-X-SEVEN-CLUB-UUID': payload.settings.company.id,
        'HTTP-X-NAB-DP': 'Web',
        'SEVEN-LOCALE': payload.settings.language,
      },
    };

    return axios(options)
      .then(response => response)
      .catch(err => Promise.reject(err.response));
  },
  generateCode({ ticket, metadata }) {
    const options = {
      url: `${routes.liveSocket}/ticketCode.php/live/ticketCode`,
      method: 'POST',
      data: {
        metadata,
        ticket,
      },
    };
    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.response));
  },
  getGeneratedTicket({ webCode, cpvUuid, locale }) {
    const options = {
      url: `${routes.liveSocket}/ticketCode.php/live/getTicketCode`,
      method: 'get',
      params: {
        webCode,
        cpvUuid,
        locale,
      },
    };

    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.response));
  },
  getPlayerSettings(params, product, vaixServiceHeaders = {}) {
    const options = {
      method: 'get',
      params: {
        prefix: params.prefix,
        product,
      },
      headers: {
        'company-name': vaixServiceHeaders.companyName,
        'vaix-client-id': vaixServiceHeaders.vaixAuthToken,
        'device-os': vaixServiceHeaders.deviceType,
      },
      url: `${routes.smGateway}/player-settings/v2/${params.playerUuid}`,
    };

    return axios(options).then(response => response)
      .catch(err => Promise.reject(err));
  },
  updatePlayerSettings(payload, product, vaixServiceHeaders = {}) {
    const options = {
      method: 'put',
      params: {
        prefix: payload.prefix,
        product,
      },
      headers: {
        'company-name': vaixServiceHeaders.companyName,
        'vaix-client-id': vaixServiceHeaders.vaixAuthToken,
        'device-os': vaixServiceHeaders.deviceType,
      },
      url: `${routes.smGateway}/player-settings/v2/${payload.playerUuid}`,
      data: payload,
    };

    return axios(options)
      .then()
      .catch((err) => { console.log('error updating player settings', err); });
  },
  getLmtBannersCmsUrl(companyName) {
    return `${routes.cms}/${companyName}/live_lmt_banner_images`;
  },
  getOnlineStatus() {
    const options = {
      method: 'get',
      url: `${routes.smGateway}/online-status`,
    };

    return axios(options).then(response => response);
  },
  getOddinESportsConfig() {
    const options = {
      method: 'get',
      url: `${routes.integrationsApi}/client/config`,
    };

    return axios(options).then(response => response);
  },
  getOddinESportsAuth({ token, language, currency }) {
    const options = {
      method: 'POST',
      data: {
        token,
        language,
        currency,
      },
      url: `${routes.integrationsApi}/client/login`,
    };

    return axios(options).then(response => response);
  },
  getOsaiTableTennisAuth(token) {
    const options = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: `${routes.osaiTableTennisIntegrations}/login`,
    };

    return axios(options).then(response => response);
  },
  async getOsaiTableTennisConfig(companyUuid) {
    const options = {
      method: 'GET',
      url: `${routes.osaiTableTennisIntegrations}/config`,
      headers: {
        'X-NSft-Seven-Tenant-ID': companyUuid,
      },
    };

    return axios(options).then(response => response);
  },
  getOddinMatchesCount() {
    const options = {
      method: 'GET',
      url: `${routes.integrationsApi}/client/matches/count`,
    };

    return axios(options).then(response => response.data);
  },
  getArticle({ articleName, companyUuid, language }) {
    return axios({
      url: `${routes.gravityApi}/v1/web/articles/${articleName}`,
      method: 'get',
      headers: {
        'X-Nsft-SCD-Company-Id': companyUuid,
        'X-Nsft-SCD-Locale': language,
      },
    })
      .then(response => response.data)
      .catch(err => Promise.reject(err.toJSON()));
  },
  getLmtEventsCoverage({
    companyUuid,
    startDate,
    extraStartDate,
    endDate,
  }) {
    const options = {
      method: 'get',
      params: {
        tenantId: companyUuid,
        dateFrom: startDate,
        dateTo: endDate,
        dataType: 'OBJECT',
      },
      url: `${routes.integrations}/api/v1/events`,
    };

    const requests = [
      axios(options),
      axios({
        ...options,
        params: {
          ...options.params,
          dateFrom: extraStartDate,
          sportId: '3,21,22', // baseball,cricket,darts
        },
      }),
    ];

    return axios.all(requests)
      .then(response => response)
      .catch(err => Promise.reject(err.response));
  },
  getXtremePushPlayerStatus({ authToken, tenantUuid, playerId }) {
    const options = {
      method: 'get',
      url: `${routes.xtremePush}/api/v1/user/enabled-notifications`,
      params: {
        attributeName: 'LiveTicketResolveUpdate',
        playerId,
      },
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-Nsft-Seven-Tenant-Uuid': tenantUuid,
      },
    };

    return axios(options)
      .then(response => response.data)
      .catch(err => Promise.reject(err.response));
  },
  checkEventAvailabilityOnXtremePush({
    authToken,
    idMatch,
    playerId,
    tenantUuid,
  }) {
    return axios({
      url: `${routes.xtremePush}/api/v1/event/check`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-Nsft-Seven-Tenant-Uuid': tenantUuid,
      },
      params: {
        events: idMatch,
        playerId,
      },
    }).then(response => response).catch(err => Promise.reject(err.response));
  },

  followEventOnXtremePush({
    authToken,
    eventId,
    playerId,
    tenantUuid,
  }) {
    return axios({
      url: `${routes.xtremePush}/api/v1/event/follow`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-Nsft-Seven-Tenant-Uuid': tenantUuid,
      },
      method: 'POST',
      params: {
        eventId,
        playerId,
      },
    }).then(response => response).catch(err => Promise.reject(err.response));
  },

  followMultipleEventsOnXtremePush({
    authToken,
    events,
    playerId,
    tenantUuid,
  }) {
    return axios({
      // eslint-disable-next-line prefer-template
      url: `${routes.xtremePush}/api/v1/event/follow-multiple?playerId=${playerId}&${events.map(event => 'eventId=' + event).join('&')}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-Nsft-Seven-Tenant-Uuid': tenantUuid,
      },
      method: 'POST',
    }).then(response => response).catch(err => Promise.reject(err.response));
  },

  unfollowEventOnXtremePush({
    authToken,
    eventId,
    playerId,
    tenantUuid,
  }) {
    return axios({
      url: `${routes.xtremePush}/api/v1/event/unfollow`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'X-Nsft-Seven-Tenant-Uuid': tenantUuid,
      },
      method: 'POST',
      params: {
        eventId,
        playerId,
      },
    }).then(response => response).catch(err => Promise.reject(err.response));
  },
};
