import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Live',
      component: () => import(/* webpackChunkName: "liveComponent" */'@/components/live/Live'),
      props: true,
    },
    {
      path: '/ticket-history',
      name: 'LiveTicketHistory',
      component: () => import('@/components/live/common/standalone-player-ticket/TicketHistory'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "liveComponent" */'@/store/modules/live')
          .then((importedModule) => {
            if (!store.state.live) store.registerModule('live', importedModule.default);
            next();
          });
      },
    },
    {
      path: '/ticket-details',
      name: 'LiveTicketDetails',
      component: () => import('@/components/live/common/standalone-player-ticket/TicketDetails'),
      props: true,
      beforeEnter(to, from, next) {
        import(/* webpackChunkName: "liveComponent" */'@/store/modules/live')
          .then((importedModule) => {
            if (!store.state.live) store.registerModule('live', importedModule.default);
            next();
          });
      },
    },
  ],
});

export default router;
