export default {
  desktop: {
    LiveThemeImporter: () => import(/* webpackChunkName: "themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "themeWrapLiveLight" */'./LiveLightThemeImporter'),
  },
  mobile: {
    LiveThemeImporter: () => import(/* webpackChunkName: "themeWrapLive" */'./LiveThemeImporter'),
    LiveLightThemeImporter: () => import(/* webpackChunkName: "themeWrapLiveLight" */'./LiveLightThemeImporter'),
  },
};
